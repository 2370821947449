import {
  FamilyBonusCardOffer,
  getFormattedRedemptionLimitsForOffer,
  getFormattedSavingsForOffer,
} from "../../../model/family.bonus.card";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import intl from "react-intl-universal";
import * as React from "react";

interface Props {
  offer: FamilyBonusCardOffer;
  detailView: boolean;
  onShowDetails?: () => void;
  onRedeem: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerRow: {
      [theme.breakpoints.up("md")]: {
        marginRight: 32,
      },
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: theme.separator,
    },
    offerRowDetailView: {
      paddingBottom: 16,
    },
    offerTitle: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.mediumFont,
    },
    offerValidity: {
      fontSize: theme.fontSizes.smallFont,
    },
    offerSavings: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.mediumFont,
      color: "#FF6EA8",
    },
    showOfferDetails: {
      textDecoration: "underline",
      textUnderlineOffset: 4,
      cursor: "pointer",
      fontSize: theme.fontSizes.smallFont,
      fontWeight: "bold",
    },
    actionButton: {
      padding: 20,
      [theme.breakpoints.down("xs")]: {
        flex: 1,
      },
    },
  }),
);

export default function FbcOfferView(props: Props) {
  const { offer, detailView, onShowDetails, onRedeem } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      item
      xs={12}
      container
      className={detailView ? classes.offerRowDetailView : classes.offerRow}>
      <Grid
        item
        xs={12}
        container
        direction={"row"}
        justify={"space-between"}
        alignItems={"center"}
        wrap={"nowrap"}>
        <Grid item>
          <Typography variant={detailView ? "h3" : "h5"}>
            {offer.title}
            {offer.currently_redeemable
              ? ""
              : intl.get("event_details.offer_details.not_redeemable")}
          </Typography>
        </Grid>
        {!detailView && (
          <Grid item>
            <Button
              disabled={!offer.currently_redeemable}
              size="small"
              color={"primary"}
              className={classes.actionButton}
              onClick={() => {
                onRedeem();
              }}>
              {intl.get("event_details.offer_details.action_redeem")}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.offerSavings}
        style={{ marginTop: detailView ? 4 : 0 }}>
        {intl.get("event_details.offer_details.savings_per_usage", {
          savings: getFormattedSavingsForOffer({ offer }),
        })}
      </Grid>
      {detailView && (
        <Grid
          item
          xs={12}
          container
          direction="column"
          style={{ marginTop: 4, marginBottom: 4 }}>
          {offer.description.split("\n").map(function (item, idx) {
            return (
              <div key={"" + idx}>
                {item === "" && <br />}
                {item !== "" && <div>{item}</div>}
              </div>
            );
          })}
        </Grid>
      )}
      {offer.validity_periods.length === 1 && (
        <Grid item xs={12} className={classes.offerValidity}>
          {intl.get("event_details.offer_details.validity_period", {
            formatted_period: offer.validity_periods[0].formatted_validity,
          })}
        </Grid>
      )}

      {offer.validity_periods.length > 1 && !detailView && (
        <Grid item xs={12} className={classes.offerValidity}>
          {intl.get("event_details.offer_details.multiple_validity_periods")}
        </Grid>
      )}
      {offer.validity_periods.length > 1 && detailView && (
        <>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ marginBottom: "10px" }}>
            <Typography variant="h6">
              {intl.get("event_details.offer_details.title_validity_periods")}
            </Typography>
            {offer.validity_periods.map((value) => {
              return (
                <Grid item xs={12} className={classes.offerValidity}>
                  {value.formatted_validity}
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.offerValidity}>
        {getFormattedRedemptionLimitsForOffer({ offer })}
      </Grid>
      <Grid item xs={12} className={classes.offerValidity}>
        {offer.offer_redemption_type === "offline" &&
          intl.get("event_details.offer_details.redemption_offline")}
        {offer.offer_redemption_type === "online" &&
          intl.get("event_details.offer_details.redemption_online")}
        {offer.offer_redemption_type === "mixed" &&
          intl.get("event_details.offer_details.redemption_mixed")}
      </Grid>
      {!detailView && (
        <Grid
          item
          xs={12}
          className={classes.showOfferDetails}
          onClick={() => {
            onShowDetails();
          }}>
          {intl.get("event_details.offer_details.show_all_details")}
        </Grid>
      )}
    </Grid>
  );
}
